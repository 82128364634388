<template>
  <FormQuestion
    @back="$emit('back')"
    @click:notes="$emit('click:notes')"
    @next="validateAddress"
    :bold="question.is_bold"
    :class="landscape ? 'my-0' : 'mb-6'"
    :color="color"
    :conditional="question.conditions.length > 0"
    :dense="dense"
    :display-notes="displayNotes"
    :elevation="elevation"
    :header="header"
    :hide-actions="hideActions"
    :landscape="landscape"
    :mandatory="question.mandatory"
    :model-value="modelValue"
    :next-disabled="nextDisabled"
    :notes-count="notesCount"
    :outlined="outlined"
    :paddingless="paddingless"
    :processing="processing || localProcessing"
    :published="question.published"
    :reversible="reversible"
    :show-indicators="showIndicators"
    :subtitle="question.subtitle"
    :tile="tile"
    :title="question.title"
    :very-dense="veryDense"
  >
    <v-row class="d-flex align-end">
      <DefinedPropertyElement
        v-for="attribute in validAddressFields"
        :key="attribute.name"
        :attribute-name="attribute.name"
        :lg="lgForAttribute(attribute)"
        :md="lgForAttribute(attribute)"
        :properties="attributeProperties(attribute.name)"
      >
        <template #default="{ properties }">
          <v-row>
            <LabeledControl
              :message="properties.alias || properties.title"
              mandatory
            >
              <Field
                v-model="modelValue[attribute.name]"
                @change="handleChange('change', $event, attribute)"
                @change:input="handleChange('change:input', $event, attribute)"
                :dense="dense"
                :landscape="landscape"
                :processing="processing"
                :properties="properties"
                :readonly="readonly"
                :very-dense="veryDense"
                condensed
                mandatory
              />
            </LabeledControl>
          </v-row>
        </template>
      </DefinedPropertyElement>
    </v-row>

    <AddressVerificationDialog
      @confirm="updateHomeAddress"
      ref="validateAddressDialog"
      :address-key="addressKey"
      :city-key="cityKey"
      :state-key="stateKey"
      :zip-key="zipKey"
    />
  </FormQuestion>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import Field from '@/shared/components/form/Field.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import DefinedPropertyElement from '@/shared/components/DefinedPropertyElement.vue';
import { validateInputAgainstAttributeMask } from '@/shared/services/MaskValidator';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR =
  'Your address could not be validated. Please check for any errors and retry. If you believe this address to be correct, please contact support for assistance.';
const USER_ENTERED_ADDRESS_ATTRIBUTES = ['address', 'city', 'state', 'zip'];

const props = defineProps({
  color: { default: 'transparent', type: String },
  condensed: { type: Boolean, default: false },
  dense: { default: false, type: Boolean },
  displayNotes: { type: Boolean, default: false },
  veryDense: { type: Boolean, default: false },
  elevation: { default: 0, type: Number },
  expanded: { default: false, type: Boolean },
  header: { type: String, default: '' },
  hideActions: { type: Boolean, default: false },
  hideField: { type: Boolean, default: false },
  showIndicators: { type: Boolean, default: false },
  landscape: { type: Boolean, default: false },
  notesCount: { type: Number, default: 0 },
  outlined: { type: Boolean, default: false },
  paddingless: { type: Boolean, default: false },
  processing: { default: false, type: Boolean },
  question: { default: () => ({}), type: Object },
  readonly: { type: Boolean, default: false },
  reversible: { type: Boolean, default: true },
  schema: { default: () => ({}), type: Object },
  tile: { type: Boolean, default: false },
});

const modelValue = defineModel({ type: Object });

const emit = defineEmits([
  'back',
  'change',
  'change:input',
  'click:notes',
  'next',
  'update:modelValue',
]);

const localProcessing = ref(false);
const validateAddressDialog = ref(null);

const validAddressFields = computed(() => {
  return USER_ENTERED_ADDRESS_ATTRIBUTES.map((attributeName) => {
    return props.question.synced_attributes.find((attribute) =>
      attribute.name.includes(attributeName),
    );
  });
});

const attributeKeys = computed(() =>
  props.question.synced_attributes.map((attribute) => attribute.name),
);

const addressKey = computed(() => extractKey('address'));
const stateKey = computed(() => extractKey('state'));
const cityKey = computed(() => extractKey('city'));
const zipKey = computed(() => extractKey('zip'));
const latitudeKey = computed(() => extractKey('latitude'));
const longitudeKey = computed(() => extractKey('longitude'));
const countyKey = computed(() => extractKey('county'));

const nextDisabled = computed(() => {
  return validAddressFields.value.some((attribute) => {
    let value;

    if (attribute.name?.includes('custom.')) {
      value = modelValue.value.custom[attribute.name.split('.')[1]];
    } else {
      value = modelValue.value[attribute.name];
    }

    if (value == null || value.toString().length === 0) return true;

    const properties = attributeProperties(attribute.name);

    if (Array.isArray(value)) {
      if (value.length === 0) return true;

      const itemsProperties = properties?.items?.properties;

      return value.some((item) => {
        const entries = Object.entries(item);

        if (entries.length === 0) return true;
        if (entries.some((value) => value[1] == null || value[1].toString().length === 0))
          return true;

        if (!itemsProperties) return false;

        const invalidEntries = entries.filter((entry) => {
          const itemProperties = itemsProperties[entry[0]];
          const itemValue = entry[1];
          return !validateInputAgainstAttributeMask(itemProperties, itemValue);
        });

        return invalidEntries.length !== 0;
      });
    }

    return !validateInputAgainstAttributeMask(properties, value);
  });
});

const attributeProperties = (attributeName) => {
  return props.schema.properties[attributeName] || {};
};

const handleChange = (emitType, value, attribute) => {
  return emit(emitType, {
    attribute,
    value,
  });
};

const lgForAttribute = (attribute) => {
  if (attribute.name.includes('address')) return '12';
  if (attribute.name.includes('city')) return '6';
  if (attribute.name.includes('state')) return '3';
  if (attribute.name.includes('zip')) return '3';
  return '6';
};

const next = () => {
  emit('next');
};

const updateHomeAddress = (uspsData) => {
  const address = uspsData.standardizedAddress;
  modelValue.value[addressKey.value] = address.firstAddressLine;
  modelValue.value[cityKey.value] = address.city;
  modelValue.value[stateKey.value] = address.state;
  modelValue.value[zipKey.value] = address.zipCode;
  modelValue.value[latitudeKey.value] = uspsData.location.latitude;
  modelValue.value[longitudeKey.value] = uspsData.location.longitude;
  modelValue.value[countyKey.value] = uspsData.county;
  next();
};

const extractKey = (key) => {
  return attributeKeys.value.find((attr) => attr.includes(key));
};

const validatedAddressMatches = (validated) => {
  return (
    modelValue.value[zipKey.value] === validated.zipCode &&
    modelValue.value[stateKey.value].toLowerCase() === validated.state.toLowerCase() &&
    modelValue.value[cityKey.value].toLowerCase() === validated.city.toLowerCase() &&
    modelValue.value[addressKey.value].toLowerCase() === validated.firstAddressLine.toLowerCase()
  );
};

const validateAddress = async () => {
  localProcessing.value = true;

  const params = {
    addressLines: [modelValue.value[addressKey.value]],
    administrativeArea: modelValue.value[stateKey.value],
    locality: modelValue.value[cityKey.value],
    postalCode: modelValue.value[zipKey.value],
  };

  const response = await api.member.address_validation.validate(params);

  localProcessing.value = false;
  if (!response.data) {
    return eventBus.chime('Unknown error occurred attempting to validate your address.');
  }

  const { hasUnconfirmedComponents } = response.data.verdict;

  if (hasUnconfirmedComponents) {
    const allSubpremise = response.data.address.addressComponents.every((addressComponent) => {
      if (addressComponent.confirmationLevel === 'CONFIRMED') return true;
      if (addressComponent.componentType === 'subpremise') return true;
      return false;
    });

    if (!allSubpremise) return eventBus.longChime(ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR);
  }

  const { geocode, uspsData } = response.data;

  if (validatedAddressMatches(uspsData.standardizedAddress)) {
    modelValue.value[latitudeKey.value] = geocode.location.latitude;
    modelValue.value[longitudeKey.value] = geocode.location.longitude;
    modelValue.value[countyKey.value] = uspsData.county;
    return next();
  }

  validateAddressDialog.value.open({ value: modelValue.value, ...uspsData, ...geocode });

  return true;
};
</script>
