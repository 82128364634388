<template>
  <v-row
    class="pointer"
    data-cy="match-calculator-button"
  >
    <v-col class="d-flex align-center">
      <template v-if="inputs.saved">
        <v-icon
          color="primary"
          icon="monetization_on"
          size="28"
          start
        />
        <div data-cy="match-tuition-credit">
          {{ $t('Eligible tuition credit:') }} {{ currency(value) }} / {{ $t('month') }}
        </div>
        <v-tooltip
          color="primary"
          location="bottom"
          max-width="400"
        >
          <template #activator="{ tooltipProps }">
            <v-icon
              v-bind="tooltipProps"
              color="primary"
              icon="info"
              end
            />
          </template>
          <span
            v-t="
              'This is an estimate of how much money the Denver Preschool Program \
            will pay each month to this school on your family\'s behalf once your child is \
            enrolled at the school and you have applied for DPP tuition credits. This is only \
            an estimate and not a guarantee. All families receive tuition credits regardless of income.'
            "
          />
        </v-tooltip>
      </template>
      <template v-else>
        <v-icon
          color="primary"
          icon="info"
          size="28"
          start
        />
        <div
          class="fs-14 fw-400"
          data-cy="match-view-more"
        >
          {{ $t('Estimate my DPP Tuition Credit') }}
        </div>
        <v-spacer />
        <v-icon
          class="fs-22 fw-500"
          icon="chevron_right"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script setup>
import { currency } from '@/plugins/filters';

const T1_INCOME = {
  2: 26621,
  3: 33560,
  4: 40499,
  5: 47438,
  6: 54377,
  7: 61316,
  8: 68254,
  9: 75193,
  10: 82132,
  11: 89071,
  12: 96010,
};

const T1_TUITION = {
  1: [820, 713, 356],
  2: [913, 794, 397],
  3: [1054, 916, 458],
  4: [1113, 967, 484],
  5: [1227, 1067, 534],
};

const T2_INCOME = {
  2: [26622, 53244],
  3: [33561, 67122],
  4: [40500, 81000],
  5: [47439, 94878],
  6: [54378, 108756],
  7: [61317, 122634],
  8: [68255, 136512],
  9: [82133, 150390],
  10: [96011, 164268],
  11: [109889, 178146],
  12: [123767, 192024],
};

const T2_TUITION = {
  1: [738, 642, 321],
  2: [822, 715, 357],
  3: [949, 825, 412],
  4: [1001, 871, 435],
  5: [1104, 960, 480],
};

const T3_INCOME = {
  2: [53245, 69020],
  3: [67123, 87010],
  4: [81001, 105000],
  5: [94879, 122990],
  6: [108757, 140980],
  7: [122635, 158970],
  8: [136513, 176960],
  9: [154503, 194950],
  10: [172493, 212940],
  11: [190483, 230930],
  12: [208473, 248920],
};

const T3_TUITION = {
  1: [697, 606, 303],
  2: [776, 675, 338],
  3: [896, 779, 390],
  4: [946, 822, 411],
  5: [1043, 907, 453],
};

const T4_INCOME = {
  2: [69021, 78880],
  3: [87011, 99440],
  4: [105001, 120000],
  5: [122991, 140560],
  6: [140981, 161120],
  7: [158971, 181680],
  8: [176961, 202240],
  9: [197521, 222800],
  10: [218081, 243360],
  11: [238641, 263920],
  12: [259201, 284480],
};

const T4_TUITION = {
  1: [574, 499, 249],
  2: [639, 556, 278],
  3: [738, 642, 321],
  4: [779, 677, 339],
  5: [859, 747, 373],
};

const T5_TUITION = {
  1: [164, 143, 71],
  2: [183, 159, 79],
  3: [211, 183, 92],
  4: [223, 193, 97],
  5: [245, 213, 107],
};

const PARTICIPATION = {
  'Extended day': 0,
  'Full day': 1,
  'Half day': 2,
};

const props = defineProps({
  inputs: {
    type: Object,
    default: null,
  },
  provider: {
    type: Object,
    default: null,
  },
});

const value = computed(() => {
  if (T1_INCOME[props.inputs.family_size] >= props.inputs.income) {
    return T1_TUITION[props.provider.quality_rating_score || 1][
      PARTICIPATION[props.inputs.participation]
    ];
  }
  if (T2_INCOME[props.inputs.family_size][1] >= props.inputs.income) {
    return T2_TUITION[props.provider.quality_rating_score || 1][
      PARTICIPATION[props.inputs.participation]
    ];
  }
  if (T3_INCOME[props.inputs.family_size][1] >= props.inputs.income) {
    return T3_TUITION[props.provider.quality_rating_score || 1][
      PARTICIPATION[props.inputs.participation]
    ];
  }
  if (T4_INCOME[props.inputs.family_size][1] >= props.inputs.income) {
    return T4_TUITION[props.provider.quality_rating_score || 1][
      PARTICIPATION[props.inputs.participation]
    ];
  }
  return T5_TUITION[props.provider.quality_rating_score || 1][
    PARTICIPATION[props.inputs.participation]
  ];
});
</script>
