import axios from 'axios';
import adminApi from '@/admin/services/bright_finder';
import baseApi from '@/shared/services/bright_finder';
import managerApi from '@/manager/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import deepmerge from 'deepmerge';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';

axios.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Error Response:', error.response.data);
      console.error('Error Status:', error.response.status);
      // You can add custom error handling logic here
      if (error.response.status === 401) {
        // Redirect to login or show a message
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error Request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error Message:', error.message);
    }
    // Optionally, you can return a rejected promise to propagate the error
    return Promise.reject(error);
  },
);

export default deepmerge.all([baseApi, managerApi, organizationApi, adminApi]);
