import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/reviewers`;
  },

  async index({ schemaId }) {
    return axios
      .get(this.baseUrl(), {
        params: { schema_id: schemaId },
      })
      .catch(handleError);
  },
};
