import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  url(id) {
    if (id) return `${window.api_url}/organization/linked_records/${id}`;
    return `${window.api_url}/organization/linked_records`;
  },

  create(resource) {
    return axios.post(this.url(), { linked_record: resource }).catch(handleError);
  },

  index(id, type) {
    return axios
      .get(this.url(), { params: { record_id: id, record_type: type } })
      .catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.url(id)).catch(handleError);
  },
};
