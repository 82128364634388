<template>
  <v-container class="pt-16">
    <v-card
      class="pa-4 mx-auto"
      max-width="600"
      border
      tile
    >
      <h1
        class="d-flex justify-center fs-28 pa-4 code-sent-header"
        tabindex="0"
      >
        {{ $t(translateHeader()) }}
      </h1>

      <v-card-text>
        <v-row>
          <v-col>
            <p
              class="mb-0 fs-16"
              tabindex="0"
            >
              {{ $t(translateLoginMessage()) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center justify-center">
            <v-otp-input
              v-model="code"
              @finish="check"
              ref="otp"
              :disabled="processing || confirmed"
              class="mxw-600"
              data-cy="otp-input"
              length="6"
              autofocus
            />

            <template v-if="confirmed">
              <v-icon
                color="green"
                icon="check_circle"
                size="36"
                end
              />
            </template>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn
            v-if="isEmail()"
            @click="openEmailConfirmation"
            variant="plain"
          >
            {{ $t('Resend login code') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <DecisionDialog
      @cancel="routeToReenterEmail()"
      @confirm="createLoginCode()"
      ref="confirmEmailDialog"
      :cancel-button-text="$t('Try another email')"
      :confirm-button-text="$t('Confirm and send')"
      :content="routeQuery.email_sent_to"
      :processing="processing"
      :title="$t('Resend login code to') + ':'"
    />

    <AddPhoneDialog
      @cancel="routeToDestination()"
      @change="routeToDestination()"
      ref="phoneDialog"
      cancel-button-text="Skip for now"
      save-button-text="Send confirmation code"
      title="Add a cell phone number"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import AddPhoneDialog from '@/shared/components/AddPhoneDialog.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const eventBus = useEventBus();
const { t } = useI18n();

const props = defineProps({
  routeParams: {
    type: Object,
    required: true,
  },
  routeQuery: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['replace']);

const code = ref(null);
const confirmed = ref(false);
const confirmEmailDialog = ref(null);
const phoneDialog = ref(null);
const processing = ref(false);

function check() {
  processing.value = true;

  Api.member.session.create(
    {
      code: code.value,
      code_id: props.routeQuery.token,
    },
    (response) => {
      if (response.data?.status === 'require_sso') {
        eventBus.chime('Your account is required to use Single Sign-On.');
        return reroute({ path: '/' });
      }

      processing.value = false;
      store.dispatch('identify', {
        success() {
          confirmed.value = true;
          eventBus.chime('Login successful');

          if (store.state.config.enable_optional_phone && response.data.prompt_for_phone) {
            phoneDialog.value.open();
          } else {
            routeToDestination();
          }
        },
        failure() {
          eventBus.chime('An unknown problem has occurred. Please contact support.');
        },
      });

      return true;
    },
    () => {
      processing.value = false;
      eventBus.chime(
        'Incorrect login code. If you need assistance logging in, please contact support.',
      );
      code.value = null;
    },
  );
}

function createLoginCode() {
  const email = props.routeQuery.email_sent_to;
  processing.value = true;
  Api.member.login_code.create(
    {
      email,
      organization_id: window.organization_id,
      site_id: window.site_id,
      is_provider: props.routeQuery.is_provider,
      is_specialist: props.routeQuery.is_specialist,
    },
    (response) => {
      confirmEmailDialog.value.close();
      processing.value = false;

      reroute({
        name: 'CodeSent',
        query: {
          ...props.routeQuery,
          mode: 'login',
          token: response.data.code_id,
          email_sent_to: email,
        },
      });
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function isEmail() {
  return !_.isNil(props.routeQuery.email_sent_to);
}

function openEmailConfirmation() {
  return confirmEmailDialog.value.open();
}

function reroute(route) {
  emit('replace', route);
}

function routeToReenterEmail() {
  if (props.routeQuery.mode === 'register') {
    reroute({ name: 'Signup', query: { mode: 'signup' } });
  } else if (props.routeQuery.mode === 'login') {
    reroute({ name: 'Signup', query: { mode: 'login' } });
  } else {
    reroute({ name: 'Welcome' });
  }
}

function routeToDestination() {
  if (props.routeQuery.redirect_url) {
    reroute(props.routeQuery.redirect_url);
    return;
  }

  if (props.routeQuery.action === 'apply') {
    reroute({
      name: 'ChildSubsidyNew',
      params: {
        subsidyProgramId: props.routeQuery.subsidyProgramId,
      },
    });
  } else if (props.routeQuery.action === 'childNew') {
    reroute({ name: 'ChildNew' });
  } else if (props.routeQuery.action === 'favorite') {
    reroute({
      name: 'SearchShow',
      params: {
        searchId: props.routeQuery.searchId,
      },
      query: {
        providerId: props.routeQuery.providerId,
        page: props.routeQuery.page || 1,
      },
    });
  } else if (props.routeQuery.action === 'providerReferral') {
    reroute({
      name: 'ChildSubsidyNew',
      params: {
        subsidyProgramId: props.routeQuery.subsidyProgramId,
      },
      query: {
        programId: props.routeQuery.programId,
      },
    });
  } else if (props.routeQuery.destination === 'messages') {
    reroute({ name: 'MessageIndex' });
  } else if (props.routeQuery.destination === 'tour') {
    reroute({
      name: 'TourShow',
      params: {
        tourId: props.routeQuery.tour_id,
      },
      query: {
        action: 'confirm',
      },
    });
  } else if (props.routeQuery.destination === 'provider') {
    reroute({
      name: 'PublicProviderShow',
      params: {
        providerId: props.routeQuery.provider_id,
      },
    });
  } else {
    reroute({ name: 'Dashboard' });
  }
}

function translateHeader() {
  const email = props.routeQuery.email_sent_to;
  const { phone } = props.routeQuery;
  return `${t('A login code was sent to')} ${email || phone}`;
}

function translateLoginMessage() {
  const email = props.routeQuery.email_sent_to;

  if (email) {
    return t('Enter the login code below or follow the link in the email to login automatically.');
  }

  return t('Enter the login code sent via text.');
}
</script>

<style scoped>
.v-otp-input input {
  text-transform: capitalize;
}

.code-sent-header {
  font-size: 1.75rem;
  padding: 1rem;
  word-break: break-word;
}

@media (max-width: 37.5rem) {
  .code-sent-header {
    font-size: 1.375rem;
    padding: 0.5rem;
  }
}
</style>
