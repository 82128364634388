import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/organization/businesses/${id}`;
    return `${window.api_url}/organization/businesses`;
  },

  create(resource, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { business: resource }).catch(handleError);
    axios.post(this.baseUrl(), { business: resource }).then(success).catch(failure);
  },

  destroy(id, success) {
    axios.delete(this.baseUrl(id)).then(success);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success).catch(handleError);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
    return axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
  },

  update(id, resource, success, failure) {
    axios.patch(this.baseUrl(id), { business: resource }).then(success).catch(failure);
  },
};
