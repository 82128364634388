export default function usePasswordValidation() {
  function isValidPassword(password, passwordConfirmation) {
    if (!password) return false;
    if (password !== passwordConfirmation) return false;
    if (password.length < 8) return false;

    let requirementsCount = 0;

    if (/[A-Z]/g.test(password)) requirementsCount += 1;
    if (/[a-z]/g.test(password)) requirementsCount += 1;
    if (/[0-9]/g.test(password)) requirementsCount += 1;
    if (/[.,/#!$%^&*;:{}=\-_`~()]/g.test(password)) requirementsCount += 1;

    return requirementsCount >= 3;
  }

  return {
    isValidPassword,
  };
}
