<template>
  <v-dialog
    v-model="isVisible"
    @click:outside="maybeClose"
    @keydown.esc="maybeClose"
    :fullscreen="fullscreen"
    :max-width="fullscreen ? null : maxWidth"
    :scrim="scrim || undefined"
    persistent
  >
    <v-card
      data-cy="resource-dialog"
      border
      tile
    >
      <v-card-title
        v-if="titleOverride || title"
        :class="titleClass"
      >
        <v-row
          class="d-flex align-center justify-start"
          dense
        >
          <v-col
            class="wrap-title"
            cols="10"
            tabindex="0"
          >
            <div
              class="wrap-text"
              data-cy="resource-dialog-title"
              data-testid="resource-dialog-title"
            >
              {{ $t(titleOverride || title) }}
            </div>
          </v-col>

          <v-col
            class="d-flex justify-end"
            cols="2"
            tabindex="0"
          >
            <slot name="title-actions" />

            <slot name="close">
              <v-btn
                v-if="closeable"
                @click="close"
                :aria-label="$t('Close dialog')"
                variant="text"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </slot>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="subTitle">
        <v-row
          class="d-flex align-center justify-start"
          dense
        >
          <v-col
            cols="12"
            tabindex="0"
          >
            <div class="fs-16 text-wrap">
              {{ $t(subTitle) }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text :class="textClass || defaultTextClass">
        <slot name="description" />
        <slot name="prepend" />
        <slot
          :local-value="localValue"
          name="form"
        >
          <v-row dense>
            <template v-for="(field, index) in fields">
              <template v-if="!field.hidden || !field.hidden(localValue)">
                <template v-if="field.items">
                  <template v-if="field.search">
                    <v-col
                      :key="index"
                      class="mb-2"
                    >
                      <v-row dense>
                        <v-col
                          class="labeled-input"
                          cols="12"
                        >
                          {{ $t(field.text) }}

                          <RequiredIndicator v-if="field.required" />
                        </v-col>

                        <v-col>
                          <v-autocomplete
                            v-model="localValue[field.value]"
                            @update:search="$emit('search', [field.value, $event])"
                            @vue:mounted="$emit('search', [field.value, ''])"
                            :chips="field.multiple"
                            :closable-chips="field.multiple"
                            :data-cy="field.dataCy || 'resource-dialog-search'"
                            :item-title="field.itemText"
                            :item-value="field.itemValue"
                            :items="field.items"
                            variant="filled"
                            auto-select-first
                            clearable
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                  <template v-else>
                    <LabeledAutocomplete
                      v-model="localValue[field.value]"
                      @change="$emit('change', [field.value, $event])"
                      :key="index"
                      :chips="field.multiple"
                      :cols="field.cols"
                      :data-cy="field.dataCy || 'resource-dialog-select'"
                      :deletable-chips="field.multiple"
                      :description="field.description"
                      :item-title="field.itemText"
                      :item-value="field.itemValue"
                      :items="Array.isArray(field.items) ? field.items : field.items(localValue)"
                      :mandatory="field.required"
                      :message="field.text"
                      :multiple="field.multiple"
                      :placeholder="field.placeholder"
                      class="mb-2"
                      clearable
                    />
                  </template>
                </template>

                <template v-else>
                  <template v-if="field.multiple">
                    <LabeledCombobox
                      v-model="localValue[field.value]"
                      :key="index"
                      :cols="field.cols"
                      :data-cy="field.dataCy || 'resource-dialog-combo'"
                      :description="field.description"
                      :message="field.text"
                      class="mb-2"
                      chips
                      deletable-chips
                      multiple
                    />
                  </template>

                  <template v-else>
                    <template v-if="field.type == 'boolean'">
                      <v-checkbox
                        v-model="localValue[field.value]"
                        :key="index"
                        :data-cy="field.dataCy || 'resource-dialog-checkbox'"
                        :false-value="false"
                        :label="field.text"
                        :true-value="true"
                        hide-details
                      />
                    </template>

                    <template v-if="field.type == 'color-hex'">
                      <LabeledColorSelect
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-color-field'"
                        :mandatory="field.required"
                        :message="field.text"
                      />
                    </template>

                    <template v-if="field.type == 'currency'">
                      <LabeledCurrencyInput
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-currency'"
                        :description="field.description"
                        :mandatory="field.required"
                        :message="field.text"
                        class="mb-2"
                      />
                    </template>

                    <template v-if="field.type == 'date'">
                      <LabeledDatePicker
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-date'"
                        :description="field.description"
                        :mandatory="field.required"
                        :message="field.text"
                        class="mb-2"
                      />
                    </template>

                    <template v-if="field.type == 'dob'">
                      <LabeledDateOfBirth
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-dob'"
                        :description="field.description"
                        :mandatory="field.required"
                        :message="field.text"
                        class="mb-2"
                      />
                    </template>

                    <template v-if="field.type == 'hours-and-minutes'">
                      <LabeledHoursAndMinutes
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-hours-and-minutes'"
                        :description="field.description"
                        :mandatory="field.required"
                        :message="field.text"
                        class="mb-2"
                      />
                    </template>

                    <template v-if="field.type == 'number'">
                      <LabeledNumber
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-number'"
                        :description="field.description"
                        :mandatory="field.required"
                        :max="field.max"
                        :message="field.text"
                        :min="field.min"
                        class="mb-2"
                      />
                    </template>

                    <template v-if="field.type == 'string' || !field.type">
                      <template v-if="field.rows">
                        <LabeledTextarea
                          v-model="localValue[field.value]"
                          :key="index"
                          :cols="field.cols"
                          :data-cy="field.dataCy || 'resource-dialog-textarea'"
                          :mandatory="field.required"
                          :message="field.text"
                          :rows="rows"
                          class="mb-2"
                        />
                      </template>

                      <template v-else>
                        <template v-if="field.protected">
                          <LabeledTextfield
                            v-model="localValue[field.value]"
                            @copy.prevent
                            @paste.prevent
                            :key="index"
                            :cols="field.cols"
                            :data-cy="field.dataCy || 'resource-dialog-text-field'"
                            :description="field.description"
                            :mandatory="field.required"
                            :mask="field.mask"
                            :message="field.text"
                            class="mb-2"
                          />
                        </template>

                        <template v-else>
                          <LabeledTextfield
                            v-model="localValue[field.value]"
                            :key="index"
                            :cols="field.cols"
                            :data-cy="field.dataCy || 'resource-dialog-text-field'"
                            :description="field.description"
                            :mandatory="field.required"
                            :mask="field.mask"
                            :message="field.text"
                            :placeholder="field.placeholder"
                            class="mb-2"
                          />
                        </template>
                      </template>
                    </template>

                    <template v-if="field.type == 'margin'">
                      <LabeledTextarea
                        v-model="localValue[field.value]"
                        :key="index"
                        :cols="field.cols"
                        :data-cy="field.dataCy || 'resource-dialog-textarea'"
                        :description="field.description"
                        :mandatory="field.required"
                        :mask="field.mask"
                        :message="field.text"
                        class="mb-2"
                      />
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </v-row>
        </slot>

        <slot
          :local-value="localValue"
          name="append"
        />

        <template v-if="consentText">
          <p class="c-light-black fs-16 mt-6">
            {{ $t(consentText) }}
          </p>
        </template>

        <template v-if="consentLabel">
          <v-checkbox
            v-model="consent"
            :label="consentLabel"
          />
        </template>
      </v-card-text>

      <template v-if="!hideActions">
        <v-divider />

        <v-card-actions
          :class="[
            $vuetify.display.smAndDown
              ? 'd-flex flex-column mb-6'
              : 'd-flex flex-row justify-center',
            'mt-0',
            'px-4',
            'py-4',
          ]"
        >
          <slot name="actions">
            <template v-if="cancellable">
              <v-btn
                @click="cancel"
                :class="[
                  $vuetify.display.smAndDown ? 'btn-small mb-4' : 'btn-large',
                  'me-3',
                  'focus-very-visible',
                  'd-flex',
                ]"
                :loading="processing"
                class="focus-very-visible"
                color="primary"
                variant="outlined"
              >
                <span>{{ $t(cancelButtonText) }}</span>
              </v-btn>
            </template>

            <template v-if="draftable">
              <v-btn
                @click="saveDraft"
                :loading="processing"
                :ripple="false"
                class="focus-very-visible px-4"
                color="primary"
                data-cy="save-draft-button"
                variant="outlined"
              >
                <span>{{ $t(saveDraftButtonText) }}</span>
              </v-btn>
            </template>

            <v-btn
              @click="save"
              :class="[
                $vuetify.display.smAndDown ? 'btn-small' : 'btn-large',
                'me-2',
                'focus-very-visible',
                'd-flex',
                saveBtnClasses,
              ]"
              :color="saveButtonColor"
              :disabled="
                saveButtonDisabled ||
                saveButtonDisabledFunction(localValue) ||
                missingRequiredFields
              "
              :loading="processing"
              :ripple="false"
              class="focus-very-visible"
              data-cy="save-button"
              data-testid="save-button"
            >
              <span>
                {{ $t(saveButtonText) }}
              </span>
            </v-btn>
          </slot>

          <slot name="component-actions" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledColorSelect from '@/shared/components/form/LabeledColorSelect.vue';
import LabeledCombobox from '@/shared/components/form/LabeledCombobox.vue';
import LabeledCurrencyInput from '@/shared/components/form/LabeledCurrencyInput.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledHoursAndMinutes from '@/shared/components/form/LabeledHoursAndMinutes.vue';
import LabeledNumber from '@/shared/components/form/LabeledNumber.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

const route = useRoute();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  action: {
    type: String,
    default: null,
  },
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  cancellable: {
    type: Boolean,
    default: true,
  },
  centerTitle: {
    type: Boolean,
    default: false,
  },
  closeOnSave: {
    type: Boolean,
    default: false,
  },
  closeable: {
    type: Boolean,
    default: false,
  },
  consentLabel: {
    type: String,
    default: null,
  },
  consentText: {
    type: String,
    default: null,
  },
  draftable: {
    type: Boolean,
    default: false,
  },
  fields: {
    type: Array,
    default() {
      return [];
    },
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    default: 400,
    type: Number,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    default: null,
  },
  saveButtonColor: {
    type: String,
    default: 'primary',
  },
  saveButtonDisabled: {
    type: Boolean,
    default: false,
  },
  saveButtonDisabledFunction: {
    type: Function,
    default: () => false,
  },
  saveButtonText: {
    type: String,
    default: 'Save',
  },
  saveDraftButtonText: {
    type: String,
    default: 'Save draft',
  },
  scrim: {
    type: Boolean,
    default: false,
  },
  subTitle: {
    type: String,
    default: null,
  },
  textClass: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

defineExpose({ clear, close, getLocalValue, setLocalValue, open });

const darkColors = ['red'];
const emit = defineEmits(['cancel', 'change', 'close', 'save', 'save:draft', 'search']);

const consent = ref(false);
const defaultTextClass = ref('pt-6 pb-6');
const isVisible = ref(false);
const localValue = ref({});
const titleOverride = ref(null);

const missingRequiredFields = computed(() => {
  return !!(
    props.fields.some((field) => {
      if (field.required && (!field.hidden || !field.hidden(localValue.value))) {
        return !localValue.value[field.value];
      }
      return false;
    }) ||
    (props.consentLabel && !consent.value)
  );
});

const titleClass = computed(() => {
  const classes = [];
  if (props.centerTitle) classes.push('justify-center');
  return classes.join(' ');
});

const saveBtnClasses = computed(() => {
  const displayWhiteText =
    darkColors.includes(props.saveButtonColor) &&
    !(props.saveButtonDisabled || missingRequiredFields.value);

  if (displayWhiteText) return 'c-white';
  return null;
});

watch(
  () => route?.query.action,
  async (newVal) => {
    if (!props.action) return;

    if (newVal && newVal === props.action) open();
    else if (isVisible.value) await close();
  },
  { immediate: true },
);

async function cancel() {
  emit('cancel');
  await close();
}

function clear() {
  localValue.value = {};
}

async function close() {
  if (isVisible.value) {
    isVisible.value = false;
    emit('close');
    if (props.action) await updateQuery({ action: null });
  }
}

function getLocalValue() {
  return localValue.value;
}

async function maybeClose() {
  if (props.closeable) await close();
}

function open(val, title = null) {
  titleOverride.value = title;
  localValue.value = JSON.parse(JSON.stringify(val || {}));
  isVisible.value = true;
}

async function save() {
  if (props.saveButtonDisabled || missingRequiredFields.value) return false;

  if (props.closeOnSave) await close();

  return emit('save', localValue.value);
}

function saveDraft() {
  return emit('save:draft', localValue.value);
}

function setLocalValue(newVal) {
  localValue.value = JSON.parse(JSON.stringify(newVal || {}));
}
</script>

<style scoped>
.btn-small {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.btn-large {
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
}

.wrap-text {
  width: 100%;
}

.wrap-title {
  display: flex;
  text-align: left;
}

@media (max-width: 37.5rem) {
  .me-3 {
    margin-right: 0 !important;
  }
  .btn-small {
    padding: 0.2rem;
  }
}
</style>
