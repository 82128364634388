import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
// import Api from '@/shared/services/all_bright_finder';

/**
 * WARNING: NOT ENTIRELY WORKING (SPECIFICALLY API BASED CALLS - ALSO CAN CAUSE VITEST ERRORS)
 * Use existing i18n translations or translation api to translate text content in <script setup>.
 * (the in template peer to this is the translate directive)
 *
 * @returns {{
 *   $t: (inputText: String) => {value: string},
 * }}
 */
export default function useTranslator() {
  const store = useStore();
  const { fallbackLocale, locale, messages, t } = useI18n();

  function $t(inputText) {
    const translatedText = computed(() => {
      // return if locale is 'en' or 'en-US' (fallback)
      if (locale.value === 'en' || locale.value === fallbackLocale.value) return inputText;

      // We already have an i18n translation if the 'i18n.messages' either:
      //   1. includes a key (for english) or
      //   2. includes a value (for anything other than english)
      //    ...that matches the input text
      const hasExistingTranslation =
        Object.keys(messages.value).length > 0 &&
        (Object.keys(messages.value[locale.value]).includes(inputText) ||
          Object.values(messages.value[locale.value]).includes(inputText));

      if (hasExistingTranslation) return t(inputText);

      // If locale is not english, and there is no local translation,
      //  1. Return input text if automatic translations not enabled
      //  2. Return api/google based translation
      if (!store.state.config.enable_automatic_translation) return inputText;
      return inputText; // return getTranslationFromApi(inputText);
    });

    return translatedText;
  }

  // async function getTranslationFromApi(inputText) {
  //   const translationResp = await Api.translations.translate(inputText, locale.value);
  //   return translationResp?.value || inputText; // always fall back to english
  // }

  return { $t };
}
