<template>
  <v-container>
    <v-row v-if="confirmed !== null">
      <v-col class="d-flex justify-center">
        <v-card
          max-width="700"
          border
          tile
        >
          <v-card-title>
            {{ $t('Set your password') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="newPassword"
                cols="12"
                message="New password"
                type="password"
              />

              <LabeledTextfield
                v-model="newPasswordConfirmation"
                cols="12"
                message="Confirm new password"
                type="password"
              />
            </v-row>

            <v-row>
              <p
                v-t="
                  'The password must be 8 characters with 3 out of 4 of the following: Lowercase characters, Uppercase characters, Numbers, Symbols.'
                "
                class="ma-3"
              />
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn
              @click="updatePassword"
              :loading="processing"
              append-icon="east"
              color="primary"
            >
              {{ $t('Save and continue') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';
import usePasswordValidation from '@/shared/composables/usePasswordValidation';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const confirmed = ref(null);
const eventBus = useEventBus();
const { isValidPassword } = usePasswordValidation();
const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();

function load() {
  if (
    store.state.profile.password_set &&
    route.query.debug !== 1 &&
    !route.query.password_reset === 'true'
  ) {
    confirmed.value = true;
    router.push({ name: 'Dashboard' });
  } else {
    confirmed.value = false;
  }
}

function updatePassword() {
  if (!newPassword.value || !newPasswordConfirmation.value) {
    return eventBus.error('Password is required to continue');
  }

  if (newPassword.value !== newPasswordConfirmation.value) {
    return eventBus.error('Passwords must match to continue');
  }

  if (!isValidPassword(newPassword.value, newPasswordConfirmation.value)) {
    return eventBus.error('Password is not valid');
  }

  processing.value = true;

  return Api.member.updatePassword(
    { password: newPassword.value },
    (response) => {
      eventBus.chime('Your password has been updated');
      processing.value = false;

      if (response.data.initial_password) {
        router.push({ path: '/' });
      } else {
        router.push({ path: '/sign_out' });
      }
    },
    (error) => {
      eventBus.chime(error.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}

onMounted(load);
</script>
