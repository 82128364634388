<template>
  <v-dialog
    v-model="isVisible"
    :max-width="600"
    scrim="transparent"
    persistent
  >
    <v-card
      data-cy="resource-dialog"
      border
      flat
      tile
    >
      <v-card-title>
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col :cols="closeable ? 10 : 12">
            <div>
              {{ $t('Multi-factor authentication enrollment') }}
            </div>
          </v-col>
          <v-col
            v-if="closeable"
            class="ta-right"
          >
            <v-btn
              @click="close"
              :aria-label="$t('Close')"
              icon="close"
              size="x-small"
              variant="text"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <p>{{ $t('1. Enter the number of your SMS enabled device:') }}</p>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <MaskedInput
              v-model="phone"
              v-slot="{ inputRef, masked }"
              mask="(###) ###-####"
            >
              <v-text-field
                v-model="masked.value"
                :ref="inputRef"
                :aria-label="$t('Phone')"
                variant="filled"
                hide-details
              />
            </MaskedInput>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              @click="enroll"
              :disabled="invalidPhone || !!challenge"
              :loading="processing"
              color="primary"
            >
              {{ $t('Send one-time passcode') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="my-6" />

        <v-row dense>
          <v-col>
            <p>
              {{ $t('2. Enter the one-time passcode sent to your device:') }}
            </p>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-otp-input
              v-model="code"
              @finish="verify"
              :disabled="!challenge || processing"
              class="mxw-400"
              data-cy="otp-input"
              length="6"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import MaskedInput from '@/shared/components/form/MaskedInput.vue';
import useEventBus from '../composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const { updateQuery } = useRouterHelper();
const route = useRoute();
const router = useRouter();
const store = useStore();

const props = defineProps({
  closeable: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);

const code = ref('');
const isVisible = ref(false);
const phone = ref('');
const processing = ref(false);
const challenge = ref(null);

const invalidPhone = computed(() => {
  return phone.value?.length !== 14;
});

watch(
  () => route.query.action,
  (newVal) => {
    if (newVal === 'mfa') {
      open();
    } else if (props.closeable) {
      isVisible.value = false;
    } else {
      router.push({ query: { action: 'mfa' } });
    }
  },
  { immediate: true },
);

onMounted(async () => {
  if (!props.eager) return;
  await updateQuery({ action: 'mfa' });
});

async function close() {
  isVisible.value = false;
  code.value = '';
  phone.value = '';
  challenge.value = null;
  await updateQuery({ action: null });
}

async function enroll() {
  processing.value = true;
  const resp = await Api.member.factor
    .create({ type: 'sms', phone: phone.value.replace(/[^0-9]/g, '') })
    .catch(handleEnrollError);

  processing.value = false;
  if (resp.data) {
    eventBus.chime('One-time passcode sent to your device.');
    challenge.value = resp.data;
  }
}

function handleEnrollError(error) {
  processing.value = false;
  eventBus.error(error);
}

function handleVerifyError(error) {
  processing.value = false;
  code.value = null;
  eventBus.error(error);
}

function open() {
  isVisible.value = true;
}

async function verify() {
  processing.value = true;
  const resp = await Api.member.factor
    .update({
      code: code.value,
      type: 'sms',
      challenge_id: challenge.value.challenge_id,
    })
    .catch(handleVerifyError);

  if (resp?.status !== 200) return;

  store.dispatch('identify', {
    success(profile) {
      if (profile.workos_mfa_factor_confirmed) {
        processing.value = false;
        void close();
        eventBus.chime('MFA Activated');
        emit('change');
      }
    },
  });
}
</script>
